import Cookies from 'js-cookie'
import loadScript from 'simple-load-script'
import smoothscroll from 'smoothscroll-polyfill'

import { TRACKING_COOKIE } from './src/utils'

import 'styles/index.css'

smoothscroll.polyfill()

const cookie = Cookies.get(TRACKING_COOKIE)
const trackingAllowed = cookie !== undefined && cookie === 'true'

export { wrapRootElement } from 'components/Provider'

const TRACKING_ID = 'G-5EJ8JSL2EJ'

window.dataLayer = window.dataLayer || []

function gtag() {
  window.dataLayer.push(arguments)
}

export const onClientEntry = () => {
  if (!trackingAllowed) {
    return
  }

  gtag('js', new Date())
  gtag('config', TRACKING_ID)

  loadScript('https://www.googletagmanager.com/gtag/js?id=G-5EJ8JSL2EJ')
}

export const onRouteUpdate = ({ location }) => {
  if (!trackingAllowed) {
    return
  }

  gtag('config', TRACKING_ID, { page_path: location.href })
}

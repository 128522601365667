import React from 'react'

const Provider = ({ children }) => {
  return <>{children}</>
}

export default Provider

export const wrapRootElement = ({ element }) => {
  return <Provider>{element}</Provider>
}
